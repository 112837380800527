import React from "react"

import SEO from "../components/seo/seo"

const PoliciesPage = () => (
  <>
    <SEO title="heisentech | Web & App Design" description="Our Policies"/>
    <section className="section">
      <div className="container policies">
        <h1 class="title" title="Terms and conditions">Terms and conditions</h1>
        <h4>1. Introduction</h4>
        <p>These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions.</p>

        <h4>2. Intellectual Property Rights</h4>
        <p>Other than content you own, which you may have opted to include on this Website, under these Terms, Heisentech and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.</p>

        <h4>3. Restrictions</h4>
        <p>You are expressly and emphatically restricted from all of the following:</p>
        <ol>
          <li>Publishing any Website material in any media;</li>
          <li>Selling, sublicensing and/or otherwise commercializing any Website material;</li>
          <li>Publicly performing and/or showing any Website material;</li>
          <li>Using this Website in any way that is, or may be, damaging to this Website;</li>
          <li>Using this Website in any way that impacts user access to this Website;</li>
          <li>Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;</li>
          <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;</li>
          <li>Using this Website to engage in any advertising or marketing;</li>
        </ol>

        <p>Certain areas of this Website are restricted from access by you and Heisentech may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.</p>

        <h4>4. No warranties</h4>
        <p>This Website is provided “as is,” with all faults, and Heisentech makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.</p>

        <h4>5. Limitation of liability</h4>
        <p>In no event shall Heisentech , nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Heisentech , including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>

        <h4>6. Indemnification</h4>
        <p>You hereby indemnify to the fullest extent Heisentech from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.</p>

        <h4>7. Severability</h4>
        <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</p>

        <h4>8. Variation of Terms</h4>
        <p>Heisentech is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.</p>

        <h4>9. Assignment</h4>
        <p>Heisentech shall be permitted to assign, transfer, and subcontract its rights and<i>or obligations under these Terms without any notification or consent required. However, .you shall not be permitted to assign, transfer, or subcontract any of your rights and</i>or obligations under these Terms.</p>

        <h4>10. Entire Agreement</h4>
        <p>These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Heisentech and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.</p>

        <h4>11. Governing Law & Jurisdiction</h4>
        <p>These Terms will be governed by and construed in accordance with the laws of the State of Maharashtra, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Maharashtra for the resolution of any disputes.</p>

        <h1 class="title" title="Refund Policy">Refund Policy</h1>

        <h4>1. Refunds or Cancellations</h4>
        <p>We do not accept refund or cancellation requests. We start many of the activities even before receiving an advance. Hence, once an advance is received, the client is obligated to pursue the deal to completion. We cannot refund or cancel the deal at any stage once the advance is received by us.</p>

        <h4>2. Exchanges / Transfer of monetary consideration to another deal/project</h4>
        <p>We do not accept such a request. However, this can be decided on case to case basis. Heisentech receives all rights to accept or deny such a request or demand adequate compensation for the work already completed in running project.</p>

        <h1>Privacy Policy</h1>
        <p>Any personal information that we receive from you, you can be assured that our company is the only one who will use it. We do not rent, sell, or otherwise distribute any information from our customer database, including e-mail addresses.</p>

        <h4>Information Collection and Use</h4>
        <p>We value our customers and their privacy. We collect store and use customer information in accordance with this policy. We may store information you enter on our website. We will not sell or share identifiable personal information to any third party, except accordance with this privacy policy or unless we are required to do so by law.</p>

        <h4>Security</h4>
        <p>All personal information that is necessary to deliver you the service are locked in our secure data center. All information transmitted to our servers via the website is encrypted using state of the art encryption technology, facilitated by our Extended Validation SSL certificate.</p>

        <h4>Communications</h4>
        <p>We will use your personal information to communicate with you about ongoing deal or project. You can unsubscribe or opt out of future emails at any time.</p>

        <h4>Credit Card Information</h4>
        <p>To ensure your safety online, we maintain strict PCI compliance and do not store credit card numbers on our servers.</p>

        <h4>Changes to This Privacy Policy</h4>
        <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>

        <h1 class="title" title="Contact Us">Contact Us</h1>
        <h4>Address</h4>
        <p>105, Smit Palace, Diva Koliwada, Diva Gaothan, Sector 9, Airoli, Navi Mumbai, Maharashtra 400708.</p>

        <h4>Email</h4>
        <p><a class="link" href="mailto:info@heisentech.com">info@heisentech.com</a></p>

        <h4>Phone</h4>
        <p><a class="link" href="tel:+91 91728 86421">+91 91728 86421</a></p>
      </div>
    </section>
  </>
)

export default PoliciesPage